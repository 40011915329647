.sidebar {
    padding: 50px 50px 0 50px;
    display: flex;
    flex-direction: column;
}

.sidebar-logo {
    width: 105px;
    margin-top: 30px;
    margin-bottom: 130px;
}

.sidebar-event {
}

.sidebar.sidebar-image-only {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
}

@media (min-width: 500px) {
    .sidebar {
        padding-left: 10vw;
    }
    .sidebar-logo {
        width: 220px;
    }
    .sidebar-event {
    }
}

@media (min-width: 992px) {
    .sidebar {
        padding-left: var(--layout-margin-left, 0);
        padding-right: 0;
    }

    .sidebar-logo {
        max-width: 66%;
        display: none;
    }
    .sidebar-event {
        display: none;
    }
    .sidebar.sidebar-image-only {
        width: 300px;
        max-width: 300px;
        margin: 0;
    }

    .sidebar-live {
        .sidebar-logo {
            display: none;
        }

        .sidebar-event {
            display: none;
        }

        .sidebar {
            padding: 0;
            max-width: 280px;
        }

        .sidebar-event {
            max-width: 280px;
        }
    }
}
