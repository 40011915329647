#speaker-bio {
    .biography {
        padding: 1rem;
    }

    .row-biografy {
        margin-right: -20px;
        background: rgb(3, 52, 78);
        background: linear-gradient(90deg, rgba(3, 52, 78, 1) 2%, rgba(4, 86, 129, 1) 47%);
    }

    .container {
        margin-top: 2rem;
        padding: 1rem 1rem;
    }

    .container-descr {
        background-color: var(--color-accent);
    }

    .second-title-lg {
        color: $blue-sso;
        font-weight: 300;
    }

    .speech-desc-box img {
        width: 200px;
        height: auto;
    }

    .speech-title {
        font-size: 1.4rem;
    }

    .div-descrizione {
        justify-content: center;
        flex-grow: 2;
    }

    .photo-box svg {
        position: absolute;
        height: 2.5rem;
        top: 120px;
    }

    .photo-box.collapsed svg .cls-1 {
        fill: #898989;
        stroke: #898989;
    }

    .div-nomi {
        margin-top: 6%;
    }

    .biography {
        border: #dddddd;
        background-color: rgb(242, 246, 249);
        padding: 1rem;
        color: black;
        /*font-family: bressay, serif;*/
    }

    .read-more {
        width: 70px;
        height: auto;
    }

    .title-person {
        font-size: 19px;
        color: white;
    }
}

@media (min-width: 576px) {
    #speaker-bio {
        .speech-text {
            font-size: 17px;
        }
    }
}

@media (min-width: 768px) {
    #speaker-bio {
        .div-nomi {
            margin-top: 0%;
        }
    }
}

@media (min-width: 992px) {
    #speaker-bio {
        .container {
            margin-top: 0;
            padding: 0 20px 0 10px;
            max-width: 1500px;
        }

        .div-descrizione {
            justify-content: flex-start;
        }
        .speech-desc-box img {
            width: 150px;
        }
    }
}

@media (min-width: 1200px) {
    #speaker-bio {
        .btn-collaps {
            margin-left: 0;
            /*margin-right: 10%;*/
        }

        .title-person {
            font-size: 24px;
        }
    }
}
