@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Italic.eot');
    src:
        url('/assets/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('/assets/fonts/OpenSans-Italic.woff') format('woff'),
        url('/assets/fonts/OpenSans-Italic.ttf') format('truetype'),
        url('/assets/fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-LightItalic.eot');
    src:
        url('/assets/fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/OpenSans-LightItalic.woff') format('woff'),
        url('/assets/fonts/OpenSans-LightItalic.ttf') format('truetype'),
        url('/assets/fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Light.eot');
    src:
        url('/assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-Light.woff2') format('woff2'),
        url('/assets/fonts/OpenSans-Light.woff') format('woff'),
        url('/assets/fonts/OpenSans-Light.ttf') format('truetype'),
        url('/assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Bold.eot');
    src:
        url('/assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('/assets/fonts/OpenSans-Bold.woff') format('woff'),
        url('/assets/fonts/OpenSans-Bold.ttf') format('truetype'),
        url('/assets/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
