:root {
    --header-solo-height: 75px;
    --header-solo-logo-height: 50px;
    --header-solo-justify: center;
    --header-solo-align: center;
    --header-solo-pl: 0;
    --header-height: 40px;
    --header-bg: #{$bianco}; //linear-gradient(45deg, rgba(2,32,48,1) 17%, rgba(4,86,129,1) 69%);
    --bar-pb: 5px;
    --bar-item-bg: linear-gradient(0deg, rgba(4, 86, 129, 1) 10%, rgba(2, 40, 60, 1) 95%);
    --bar-item-bg-comp: rgb(4, 86, 129);
    --bar-item-ml: 2px;
    --bar-link-fs: 10px;
    --bar-link-fw: 400;
    --bar-link-color: #{$bianco};
    --bar-link-padding: 0.5rem 0;
    --bar-link-active-pt: 16px;
    --bar-link-height: unset;
}

header.header-live {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: var(--header-height);
    background-color: var(--header-bg, transparent);
}

header.header-solo {
    display: flex;
    height: var(--header-solo-height);
    justify-content: var(--header-solo-justify);
    align-items: var(--header-solo-align);
    padding-left: var(--layout-margin-left);

    .logo {
        height: var(--header-solo-logo-height);

        img {
            height: 100%;
            width: auto;
        }
    }
}

#nav-logo {
    display: none;
}

#bar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: var(--bar-pb);
}

.bar-item {
    background: var(--bar-item-bg-comp);
    background: var(--bar-item-bg);
    margin-left: var(--bar-item-ml);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.ml-bar {
        --bar-item-ml: 0;
    }

    &.ghost {
        display: none;
        visibility: hidden;
    }
}

.bar-link {
    font-size: var(--bar-link-fs);
    font-weight: var(--bar-link-fw);
    color: var(--bar-link-color);
    padding: var(--bar-link-padding);
    height: var(--bar-link-height);
    //line-height: var(--bar-link-height);

    display: block;
    text-align: center;
    width: 100%;
    text-decoration: none;
    animation: all 200ms ease-in-out;

    &:focus,
    &:visited,
    &:active,
    &:hover {
        color: var(--bar-link-color);
        text-decoration: none;
    }
}

.bar-item.active .bar-link {
    --bar-link-fw: 600;
    --bar-link-height: var(--header-height);

    border-bottom: 5px solid var(--color-accent);
    padding-top: var(--bar-link-active-pt);
}

@media (min-width: 576px) {
    :root {
        --header-height: 50px;
        --bar-link-fs: 15px;
    }
}

@media (min-width: 992px) {
    :root {
        --header-solo-logo-height: 70px;
        --header-solo-justify: flex-start;
        --header-solo-align: flex-end;
        --header-solo-pl: var(--layout-margin-left);
        --header-height: 75px;
        --bar-item-ml: 4px;
        --bar-link-fs: 14px;
        --bar-link-fw: 300;
        --bar-link-padding: 0.5rem;
        --bar-link-active-pt: 22px;
    }

    #main-nav {
        display: flex;
    }

    #nav-logo {
        display: block;
        position: absolute;
        height: 70px;
        top: 0;
        left: 0;
        padding-top: 3px;

        img {
            margin-left: var(--layout-margin-left, 0);
            height: 100%;
            width: auto;
        }
    }

    #bar {
        width: 100%;
    }

    .bar-item {
        &.ml-bar {
            --bar-item-ml: 350px;
        }

        &.ghost {
            display: block;
        }

        &.active .bar-link {
            --bar-link-fw: 400;
            --bar-link-height: 50px;
        }
    }
}

@media (min-width: 1200px) {
    :root {
        --bar-link-fs: 17px;
        --bar-link-padding: 0.7rem 0.5rem;
        --bar-link-active-pt: 30px;
    }

    .bar-item {
        &.ml-bar {
            --bar-item-ml: 425px;
        }

        &.active .bar-link {
            --bar-link-fw: 400;
            --bar-link-height: 60px;
        }
    }
}
