html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
html,
body {
    min-height: 100%;
}
body {
    font-family: $main-font-family, sans-serif;
    font-weight: 400;
    //-webkit-font-smoothing: antialiased;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    font-size: var(--font-size, 16px);
    color: var(--color-primary, #{$text-color});
}

body::-webkit-scrollbar {
    display: none;
}

a,
a:hover,
a:active,
a:focus {
    outline: none;
}

a {
    color: $blue-pmi;

    &:hover {
        text-decoration: underline;
        color: $blue-pmi;
    }

    &.link-highlight {
        color: $text-highlight;

        &:hover {
            color: darken($text-highlight, 15%);
        }
    }
}

/** DISABLED FOR GIGYA SCREENSETS **/
//
//input,
//textarea,
//select {
//    padding: 0;
//    margin: 0;
//    border-radius: 0;
//    appearance: none;
//    box-shadow: none;
//    text-shadow: none;
//}
//
//input[type='password'] {
//    font-family: sans-serif;
//}
//
//input:focus,
//textarea:focus,
//select:focus {
//    outline: none;
//}
//textarea {
//    resize: none;
//}
//select::-ms-expand {
//    display: none;
//}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    text-shadow: none;
    box-shadow: none;

    &:focus {
        outline: none;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    width: 100%;
    height: auto;
}

/*
video {
	width: 100%    !important;
	height: auto   !important;
}
*/

.preloading {
    * {
        transition: none !important;
    }
}

::selection {
    background: $selection-background;
    color: $selection-color;
    text-shadow: none;
}

::placeholder {
    color: $placeholder-color;
    opacity: 1;
    overflow: visible;
    font-weight: $placeholder-weight;
    font-size: $placeholder-font-size;
    font-style: $placeholder-font-style;
}
