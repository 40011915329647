.bg-generic {
    background: rgb(209, 224, 232);
    background: linear-gradient(180deg, rgba(209, 224, 232, 1) 33%, rgba(255, 255, 255, 1) 92%);
}

.bg-form {
    background: rgba(255, 255, 255, 0.95);
}

.bg-black-purple-blue {
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(100, 62, 145, 1) 50%, rgba(54, 82, 131, 1) 100%);
}

.bg-smoke {
    //background-image: url('/assets/images/bg-smoke.jpg');
    //background-size: contain;
    background-repeat: no-repeat;
    //background-position: 0 50px;
    background-color: black;

    @media (min-width: 992px) {
        background-image: url('/assets/images/bg-side-05-2024.jpg');
        background-color: white;
        background-size: contain;
        background-position: top left;
    }
}
#view {
    &.fullpage {
        min-height: 100vh;
        min-height: 100dvh;
    }

    &.landing {
        padding-bottom: 100px;
    }
}

#aside {
    &.no-max {
        max-width: unset;
    }
}
.sidebar-image-only {
    max-width: 300px;
    margin: 0 auto;
}
#box-generic {
    --box-generic-left: 0;
    --box-generic-right: 0;
    --box-generic-spacer: 3rem;
    --box-generic-top: 3rem;
    --box-generic-max-width: none;

    margin-left: var(--box-generic-left);
    margin-right: var(--box-generic-right);

    &.fluid {
        max-width: var(--box-generic-max-width);
        margin-top: var(--box-generic-top);
    }

    &.compact {
        margin-top: var(--box-generic-top);
    }

    &.box-generic-spacer {
        margin-top: var(--box-generic-spacer);
    }
}

.sidebar-generic {
    .logo-desktop {
        padding-left: 30px;
    }
}

.sidebar-logo {
    --padding: 50px 0 50px 50px;
    --logo-width: 150px;
    --logo-pb: 25px;
    --event-width: 260px;
    --display-logo: initial;

    padding: var(--padding);
    display: flex;
    flex-direction: column;

    .logo {
        width: var(--logo-width);
        padding-bottom: var(--logo-pb);
        display: var(--display-logo);
    }

    .event {
        max-width: var(--event-width);
    }
}

#box-message {
    &.box-small {
        --btn-form-spacing: 2px;
        --btn-form-padding: 10px 25px;
        max-width: 300px;
    }

    .no-spacing {
        --btn-form-spacing: 0;
    }
}

@media (min-width: 500px) {
    .sidebar-logo {
        --padding: 50px 0 50px 10vw;
        --logo-width: 200px;
        --event-width: 330px;
    }
}

@media (min-width: 992px) {
    #box-generic {
        --box-generic-left: 15px;
        --box-generic-right: auto;
        --box-generic-max-width: 750px;
        --box-generic-spacer: 150px;
        --box-generic-top: 3%;

        &.compact {
            --box-generic-left: 8.333333%;
            --box-generic-right: 0;
        }
    }
    .page .sidebar-logo {
        --display-logo: none;
    }

    .sidebar-logo {
        --padding: 50px 0 50px var(--layout-margin-left, 0);
    }

    .sidebar-live .sidebar-logo {
        --padding: 0;
        --event-width: 280px;

        max-width: 280px;
    }

    .sidebar-generic {
        .logo-desktop {
            width: 330px;
        }
    }

    .sidebar-image-only {
        margin: 0;
    }
}

@media (min-width: 1010px) {
    #box-generic {
        --box-generic-left: auto;
    }
}
