#time2go {
    --t2-mr: 25px;
    --t2-digit-size: 50px;
    --t2-digit-semicol-right: -20px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue-sso;

    .t2-counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: var(--t2-mr);

        .short {
            display: none;
        }

        .long {
            display: none;
        }

        &:last-child {
            margin-right: 0;

            .t2-digit:after {
                content: '';
            }
        }
    }

    .t2-part {
        display: none;
    }

    .t2-digit {
        font-size: var(--t2-digit-size);
        position: relative;
        display: inline-block;
        line-height: 1;
        font-weight: bold;

        &:after {
            content: ':';
            position: absolute;
            right: var(--t2-digit-semicol-right);
        }
    }
}

@media (min-width: 768px) {
    #time2go {
        --t2-mr: 35px;
        --t2-digit-size: 60px;
        --t2-digit-semicol-right: -25px;
    }
}

@media (min-width: 992px) {
    #time2go {
        --t2-mr: 40px;
        --t2-digit-size: 70px;
        --t2-digit-semicol-right: -30px;
    }
}
