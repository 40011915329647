//#aside {
//    background:red;
//}
//#content {
//    background:blue;
//}

.text-color {
    color: $text-color;
}
.text-dark {
    color: $nero-medio;
}

.text-highlight {
    color: $text-highlight;
}

.text-reverse {
    color: $text-reverse;
}

.text-error {
    color: $text-error;
}

.text-strong {
    font-weight: bold;
}

.text-bold,
.bold {
    font-weight: 700;
}

.text-semibold {
    font-weight: 600;
}

.text-regular {
    font-weight: 400;
}

.text-light {
    font-weight: 300;
}

.text-thin {
    font-weight: 200;
}

.text-primary {
    color: var(--color-primary);
}

.text-accent {
    color: var(--color-accent);
}

.link-accent {
    color: var(--color-accent);
    text-decoration: underline;

    &:hover {
        color: var(--color-primary);
        text-decoration: underline;
    }
}
.link-accent-only {
    color: var(--color-accent);

    &:hover {
        color: var(--color-accent);
        text-decoration: underline;
    }
}

.link-color {
    color: $link-color;
}

.text-underline {
    text-decoration: underline;
}

.alt-font {
    font-family: $alt-font-family;
}

.bg-highlight {
    background-color: $text-highlight;
}

.bg-white {
    background-color: $white-background;
}

.line-divider {
    border-top: 3px solid $text-color;
    height: 3px;
}

.font-fair {
    font-size: var(--font-fair-size, 16px);
}
.size-20 {
    font-size: 20px;
}
.size-22 {
    font-size: 22px;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.fade {
    transition: opacity 0.25s linear;
}

.nowrap {
    white-space: nowrap;
}

.tooltip-css {
    position: relative;
    background-color: white;
    color: #005472;
    padding: 5px 15px;
    border-radius: 100%;
    font-size: 20px;
    cursor: help;
    margin: 1px 1px 1px 10px;
    border: 1px solid #005472;
}

.tooltip-css::before,
.tooltip-css::after {
    position: absolute;
    left: 50%;
    opacity: 0;
    transition: allease0 0.3s;
}

.tooltip-css::before {
    content: '';
    border-width: 10px 8px 08px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    top: -20px;
    margin-left: -8px;
}

.tooltip-css::after {
    content: attr(data-tooltip);
    background: rgba(0, 0, 0, 0.8);
    top: -20px;
    transform: translate(-35%, -100%);
    font-size: 14px;
    margin-left: -150px;
    width: 300px;
    border-radius: 10px;
    color: #fff;
    padding: 14px;
}

.tooltip-css:hover::before,
.tooltip-css:hover::after {
    opacity: 1;
}

.tooltip-css::before.tooltip-css::after {
    transition: all ease 0.3s;
}

.nowrap {
    white-space: nowrap;
}

.with-divider {
    padding-bottom: 1rem;
    border-bottom: 1px dotted black;
}

@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

@media (min-width: 992px) {
    .to-992 {
        display: none;
    }
    .nowrap-md {
        white-space: nowrap;
    }

    .tooltip-css {
        padding: 5px 20px;
        font-size: 22px;
    }
}

@media (max-width: 991px) {
    .from-992 {
        display: none;
    }
}
