.to-top {
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: #0d5174;
    color: #fff;
    user-select: none;
    padding: 5px 10px 10px 10px;
}

.to-top:hover {
    color: #fff;
    text-decoration: none;
}
.container {
    margin-top: 2rem;
    padding: 1rem 1rem;
    color: rgb(0, 84, 114);
}

.bck-light {
    background: #e6edf1;
}

.hero h1,
.hero h2 {
    font-size: 1.2rem;
}

.hero h1 {
    font-weight: bold;
}

.hero h2 {
    font-weight: 300;
    line-height: 0.9;
}

.hero h3 {
    font-weight: 300;
    margin-top: 2rem;
    font-size: 1rem;
}

.hero h4 {
    font-size: 1rem;
}

h4.unsubscribe {
    color: #07567d;
}

.hero h6 {
    font-size: 0.8rem;
}

.hero sup {
    margin-right: 5px;
    text-transform: uppercase;
}

.border-y-fit {
    width: fit-content;
    border-bottom: 1px dotted black;
    border-top: 1px dotted black;
    padding: 1rem 0;
}

.bb-content {
    margin: 0;
    padding: 0;
    font-weight: 700 !important;
}

.thin {
    font-weight: 300 !important;
}

.top-logo-box {
    padding: 10px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-logo {
    height: 75px;
}

.header-solo {
    height: auto;
    margin-top: 0;
}

p.errors {
    color: #ff8c01e3;
    font-weight: bold;
}

.div-title {
    margin: 2rem 0;
    padding: 0 1rem 1rem 1rem;
    background: var(--sidebar-bg, #fff);

    //background: rgb(2,43,64);
    //background-image: url(/assets/img/bck-evt-40.svg), linear-gradient(90deg, rgba(2,43,64,1) 19%, rgba(4,86,129,1) 72%);
    //background-position:  right 0 bottom 12px, 0;
    //background-repeat: no-repeat;
    //background-size:  auto 85%, 100%;
    sup {
        top: -3px;
    }

    &.generic {
        background: transparent;
        margin: 0;
        padding: 0;
    }
}

#agenda #div-text {
    position: relative;

    .title-agenda {
        font-size: 22px;
        margin-left: 3%;
        color: #005472;

        &.text-left {
            //border-right: 2px solid #005472 !important;
            padding-right: 3%;
        }

        &.text-right {
            margin-left: 3% !important;
            font-weight: 500;
        }
    }

    .dotted {
        border-left: 5px dotted var(--color-accent);
        margin: 3% 0 0 3%;
        padding-bottom: 20px;
    }

    .link-linkedin {
        font-weight: bold;
        color: #005472;
    }
}

#agenda #div-text .topbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#agenda #div-text .box-title {
    text-transform: uppercase;
    color: #ce4037;
    font-size: 20px;
    letter-spacing: 5px;
    padding: 15px 10px 0 0;
}

#tolive {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

#agenda {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

#speaker-bio {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

#qa-note {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

#explore-more {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.divider-dotted-right {
    border-right: none;
}

hr.dashed {
    width: 190px;
    border-top: 1px dashed #005472;
}

hr.dotted {
    width: 100%;
    border-top: 5px dotted #f0b332;
}

#speaker-bio hr.dashed {
    width: 220px;
}

.title-agenda-lg {
    font-size: 20px;
    color: #005472;
}

.testo-agenda {
    font-size: 15px;
    color: #005472;
}

.speaker-person {
    margin-left: 2%;
    color: #005472;
}

.speaker-person.agenda-dot {
    padding-left: 13px;
}

.dot-list {
    width: 27px;
    margin-right: 12px;
}

.speaker-person-semibold {
    font-size: 18px;
    font-weight: 600;
}

.speaker-person-bold {
    font-size: 18px;
    font-weight: bold;
}

.job-person {
    color: #005472;
    font-size: 12px;
}

.question {
    text-transform: uppercase;
    display: inline-block;
    line-height: 18px;
}

.question,
.person {
    color: #07567d;
}

.question,
.person {
    color: white;
}

.row-blue {
    color: #005472;
}

.vl {
    border-left: 1px dashed #005472;
    /*height: 665px;*/
    margin-left: 10%;
}
