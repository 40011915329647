#agenda {
}

#agenda-text {
    --title-size: 22px;

    position: relative;

    .title-agenda {
        font-size: var(--title-size);
        margin-left: 3%;
        color: $blue-sso;

        &.text-left {
            //border-right: 2px solid #005472 !important;
            padding-right: 3%;
        }

        &.text-right {
            margin-left: 3% !important;
            font-weight: 500;
        }
    }

    .dotted {
        border-left: 5px dotted var(--color-accent);
        margin: 3% 0 0 3%;
        padding-bottom: 20px;
    }

    .link-linkedin {
        font-weight: bold;
        color: $blue-sso;
    }

    .topbar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .box-title {
        text-transform: uppercase;
        color: #ce4037;
        font-size: 20px;
        letter-spacing: 5px;
        padding: 15px 10px 0 0;
    }

    .dot-list {
        width: 27px;
        margin-right: 12px;
    }

    .speaker-person-semibold {
        font-size: 18px;
        font-weight: 600;
    }
    .speaker-person-bold {
        font-size: 18px;
        font-weight: bold;
    }

    .speaker-person {
        margin-left: 2%;
    }
}

@media (min-width: 768px) {
    #agenda-text {
        --title-size: 28px;
        .text-left {
            border-right: 2px solid var(--color-primary);
        }
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
    #agenda-text {
        --title-size: 30px;
    }
}
