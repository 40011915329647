#addlinks {
    max-width: 1400px;
    margin: 25px auto 75px auto;
    padding: 0 15px;

    a:hover {
        text-decoration: none;
    }

    .hero {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgb(4, 86, 129);
        background: linear-gradient(270deg, rgba(4, 86, 129, 1) 10%, rgba(2, 40, 60, 1) 95%);
        margin-bottom: 25px;
        border-left: 12px solid var(--color-accent);
        text-decoration: none;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        span {
            color: $bianco;
            font-size: 13px;
            display: block;
            margin-top: 15px;
            line-height: 1.1;
            padding-bottom: 5px;
            text-align: center;
            font-weight: 700;

            br {
                display: none;
            }
        }
    }

    .section {
        margin-top: 75px;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        .btitle {
            color: $blue-sso;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            margin-bottom: 40px;

            span {
                letter-spacing: 10px;
                padding: 0px;
                font-size: 28px;
                font-weight: 400;
            }
        }

        .btitle.v {
            flex-direction: column;
        }

        .separator {
            border-bottom: 6px dotted var(--color-accent);
            margin: 12px auto 40px auto;
        }

        .pic-grid {
            .item {
                display: block;
                background-color: #e1e8ec;
                border-bottom: 6px solid var(--color-accent);
                margin-bottom: 45px;

                span {
                    display: block;
                    line-height: 1;
                    color: $bianco;
                    padding: 50px 10px;
                    font-weight: 700;
                    font-size: 18px;
                    background: rgb(2, 44, 66);
                    background: linear-gradient(90deg, rgba(2, 44, 66, 1) 0%, rgba(4, 86, 129, 1) 69%);
                }
            }
        }

        .grid {
            .item {
                display: block;
                background-color: #e1e8ec;
                border-bottom: 10px solid #005472;
                margin-bottom: 20px;

                span {
                    display: flex;
                    align-items: center;
                    line-height: 1.2;
                    color: #005472;
                    padding: 25px 10px;
                }
            }
        }

        .item.big span {
            height: auto !important;
            padding: 10px !important;
        }
    }
}

@media (min-width: 768px) {
    #addlinks {
        margin-top: 50px;
        margin-bottom: 50px;
        --gap: 25px;

        .hero {
            display: flex;
            flex-wrap: wrap;
            max-width: none;
            gap: var(--gap);
        }

        .frame {
            flex-basis: calc(33% - var(--gap) / 3 * 2);
            margin-bottom: 0;
        }

        .section {
            margin-top: 50px;
            max-width: 1100px;

            .btitle.v {
                flex-direction: row;
            }
            .btitle span {
                padding: 0 15px;
            }

            .pic-grid,
            .grid {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .grid {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                .item {
                    display: flex;
                    flex-direction: column;
                    flex-basis: calc(50% - 10px);
                }
            }

            .pic-grid {
                .item {
                    flex-basis: calc(50% - 15px);
                    display: flex;
                    flex-direction: column;

                    span {
                        padding: 0px 10px;
                        flex-grow: 1;
                        display: flex;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    #addlinks {
        margin-bottom: 50px;
        padding: 0 15px;

        .frame {
            flex-basis: 100%;
            flex-direction: row;
            align-items: flex-start;

            .sizer {
                width: 50%;
                height: auto;
            }

            .pic {
                width: 100%;
                padding-bottom: 100%;
                position: relative;
                overflow: hidden;
                height: 0;
            }
            span {
                width: 55%;
                text-align: left;
                padding: 15px;
                margin: 0;
                font-size: 20px;
                div {
                    border-bottom: 2px dotted white;
                    padding-top: 5px;
                }
            }
            &:first-of-type span {
                word-spacing: 100vw;

                & > span {
                    word-spacing: initial;
                }
            }

            img {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }

        .section {
            .btitle span {
                letter-spacing: 15px;
                font-size: 20px;
            }

            .grid {
                .item {
                    justify-content: center;
                    height: 240px;
                    span {
                        font-size: 20px;
                        padding-right: 70px;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        #addlinks {
            --gap: 10px;

            .hero {
                justify-content: center;
            }

            .frame {
                flex-basis: calc(50% - var(--gap));
                span {
                    font-size: 14px;
                    br {
                        display: block;
                    }
                }
            }

            .section {
                .btitle.v {
                    flex-direction: row;
                }

                .btitle span {
                    letter-spacing: 17px;
                    padding: 0 16px;
                    font-size: 22px;
                }

                .pic-grid {
                    .item {
                        flex-basis: calc(33.333% - 15px);
                    }
                }

                .grid {
                    .item {
                        justify-content: center;
                        /*height: 110px;*/
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    #addlinks .frame .sizer {
        width: 40%;
    }
    #addlinks .frame span {
        width: 60%;
        font-size: 14pt;
        line-height: 14.5pt;
        /*padding-right: 40px;*/
        /*font-size: 23px;*/
    }
}

@media (min-width: 1900px) {
    #addlinks .frame .sizer {
        width: 45%;
    }
    #addlinks .frame span {
        width: 55%;
        font-size: 23px;
        line-height: 26px;
        /*padding-right: 40px;*/
        /*font-size: 23px;*/
    }
}
