#survey {
    --survey-bg-light: #edf2f7;
    --survey-color: #fff;
    --survey-blue: #{$blue-sso};

    background-color: var(--survey-bg-light);
    color: var(--survey-color);
    overflow-x: hidden;

    .poll-intro,
    .poll-outro,
    .poll-sending {
        display: none;
    }

    .questions {
        display: flex;
        height: 100%;
    }

    .poll {
        flex: 1 0 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        display: none;

        &.active {
            display: flex;
        }
    }

    .poll-number {
        color: var(--survey-blue);
        font-weight: bold;
        padding: 5px 0;
    }

    .poll-body {
        background-color: var(--survey-blue);
        padding: 0.5rem 0.5rem 4rem 0.5rem;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0.5rem;
        align-self: stretch;

        .poll-question {
            border-bottom: 4px dotted #fff;
            padding-bottom: 20px;
            margin-bottom: 2rem;
            text-align: center;
            font-size: 20px;
        }

        .poll-message {
            margin: -2rem 0 15px 0;
            text-align: center;
            font-size: 18px;
            text-transform: uppercase;
            background-color: rgba(255, 255, 255, 0.2);
            padding: 5px;
        }

        .poll-hero {
            text-align: center;
            font-size: 30px;
            text-transform: uppercase;
            padding: 5px 20px;
        }
    }

    .poll-options {
        display: flex;

        &.vertical {
            flex-direction: column;
        }
    }

    .poll-option {
        cursor: pointer;
    }

    .poll-options-viewport.viewport {
        max-height: 450px;
        overflow-y: scroll;
    }

    /* .poll-option:hover .filler,
    .poll-single-option .poll-option:hover .label,  */
    .poll-option.active .filler,
    .poll-option.active .filler,
    .poll-single-option .poll-option.active .label,
    .poll-option.fill .filler {
        opacity: 1;
    }

    /*.poll-multiple-options .poll-option:hover .label,*/
    .poll-multiple-options .poll-option.active .label {
        font-weight: bold;
    }

    .btn-op {
        max-width: 50px;
        max-height: 50px;
        width: 100%;

        svg {
            display: none;
        }

        .op-on {
            display: none;
        }
    }

    .poll-option.active .btn-op .op-on,
    .poll-option.fill .btn-op .op-on {
        display: block;
    }

    .poll-option.active .btn-op .op-off,
    .poll-option.fill .btn-op .op-off {
        display: none;
    }

    /* .btn-op:hover .filler, .btn-op.active .filler, .btn-op.fill .filler {
        opacity: 1;
    } */

    .poll-single-value {
        .poll-option {
            flex: 1;
            text-align: center;
            padding: 5px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .value {
            font-size: 20px;
        }

        .label {
            font-size: 13px;
            padding-top: 10px;
            position: absolute;
            left: 0;
            bottom: -45px;
        }

        .vertical {
            .poll-option {
                flex-direction: row;
                margin-bottom: 15px;

                .btn-op {
                    max-width: 35px;
                    max-height: 35px;
                }

                .value {
                    padding-left: 20px;
                }

                .label {
                    left: unset;
                    bottom: unset;
                    right: -100%;
                    top: 0;
                }
            }
        }
    }

    .poll-single-option {
        .poll-options {
            flex-direction: column;
        }

        .poll-option {
            display: flex;
            align-items: center;
            padding-bottom: 15px;

            .btn-op {
                width: 50px;
                height: 50px;
            }

            .label {
                padding-left: 20px;
                font-size: 20px;
                color: var(--survey-bg-light);
                opacity: 0.3;
            }
        }
    }

    .poll-multiple-options {
        .poll-options {
            display: flex;
            flex-direction: column;
        }

        .poll-option {
            display: flex;
            align-items: center;
            min-height: 80px;

            .btn-op {
                width: 35px;
                height: 35px;
                max-width: 35px;
                max-height: 35px;
                flex: 100%;
            }

            .label {
                padding-left: 20px;
                font-size: 16px;
                color: var(--survey-bg-light);
                line-height: 1.1;
            }
        }

        .free-option {
            width: 100%;

            input {
                appearance: none;
                background-color: rgb(116, 138, 154);
                border: 0 none;
                color: #fff;
                margin-left: 20px;
                margin-top: 5px;
                width: 90%;
                outline: none !important;
                font-size: 14px;
                padding: 5px;
            }
        }
    }

    .poll-buttons {
        padding: 1rem 0;
        display: flex;
    }

    .btn-poll {
        font-size: 40px;
        width: 45px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 84, 114);
        color: #fff;
        margin: 0 0.5rem;
        text-decoration: none;
        appearance: none;
        border: 0 none;
        text-shadow: none;
        box-shadow: none;

        &:hover {
            color: #000;
        }

        &:disabled {
            color: rgb(133, 133, 133);
            background-color: #fff;
            appearance: none;
        }

        &:disabled:hover {
            color: rgb(133, 133, 133);
        }
    }

    .btn-poll.btn-prev {
    }

    .btn-poll.btn-next {
    }

    .extra-small {
        width: 100px;
        margin: 0 auto;
    }

    .small {
        width: 240px;
        margin: 0 auto;
    }
}

@media (min-width: 992px) {
    #survey .poll-options-viewport.viewport {
        max-height: 650px;
        overflow-y: scroll;
    }
    #survey .poll-body .poll-hero {
        padding: 5px 3rem;
    }
    #survey .poll-body .poll-question {
        font-size: 20px;
    }

    #survey .poll-option:hover .filler,
    #survey .poll-single-option .poll-option:hover .label,
    #survey .poll-option.active .filler,
    #survey .poll-single-option .poll-option.active .label,
    #survey .poll-option.fill .filler {
        opacity: 1;
    }

    #survey .poll-option:hover .btn-op .op-on {
        display: block;
    }
    #survey .poll-option:hover .btn-op .op-off {
        display: none;
    }
}

@media (min-width: 1200px) {
    #survey .poll-multiple-options .poll-option {
        min-height: 65px;
    }
}
