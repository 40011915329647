body.page-embed {
    min-height: 100vh;
    min-height: 100dvh;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    min-height: 100vh;
    min-height: 100dvh;

    .logo {
        width: 300px;
        margin-bottom: 5rem;
    }
}
