:root {
    --ls-pt: 30px;
    --ls-pb: 80vh;
    --ls-pl: 0;
    --ls-ml: auto;
    --ls-mr: auto;
    --ls-spacer: 15vh auto;
}

body.page {
    height: 100vh;
    height: 100dvh;
    //height: calc(var(--vh, 1vh) * 100);

    font-size: 1rem;
}

#view {
    &.live-view {
        overflow-y: scroll;
        height: 100vh;
        height: 100dvh;
    }

    &.snap-view {
        scroll-snap-type: y mandatory;
        scroll-snap-points-y: repeat(100vh);
        scroll-snap-points-y: repeat(100dvh);
    }

    &.snap-view-safari {
        scroll-snap-type: y proximity;
        scroll-snap-points-y: repeat(100vh);
        scroll-snap-points-y: repeat(100dvh);
    }

    #nav-logo {
        --layout-margin-left: 1vw;
    }
}

.slide-center {
    width: 100%;

    sup {
        top: -4px;
    }
}

.live-section {
    padding-top: var(--ls-pt);
    padding-bottom: var(--ls-pb);
    padding-left: var(--ls-pl);
    margin-left: var(--ls-ml);
    margin-right: var(--ls-mr);
    scroll-snap-align: start;
    position: relative;
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &.live {
        --ls-pt: 200px;
        --ls-pl: 15px;
    }

    &.qa-note {
        --ls-pb: 75vh;
    }

    .spacer {
        margin: var(--ls-spacer);
    }

    .live-logo {
        position: absolute;
        top: 75px;
        left: var(--layout-margin-left, 0);
        width: 200px;
        height: auto;
    }
}

.one-slide {
    --ls-pb: 0;
}

#last-live-element {
    padding-top: 0px;
    scroll-snap-stop: default;
}

#qa-note {
    word-break: break-word;
}

.qa-note-icon {
    max-width: 100px;
    max-height: 100px;
}

@media (min-width: 768px) {
    :root {
        --ls-spacer: 25vh auto;
    }
}
@media (min-width: 992px) {
    :root {
        --ls-pt: 105px;
        --ls-pb: 95vh;
        --ls-pl: var(--layout-margin-left);
        --live-box-max-width: 900px;
    }

    .live-section {
        .live {
            --ls-pl: var(--layout-margin-left);
        }
        .live-logo {
            display: none;
        }
    }

    .live-box {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--live-box-max-width);
    }
}

@media (min-width: 1200px) {
    :root {
        --live-box-max-width: 1100px;
    }
}
@media (min-width: 1600px) {
    :root {
        --live-box-max-width: 1200px;
    }
}
