.pmi-card {
    --color: #{$blue-pmi};
    --max-width: 440px;
    --padding: 1rem 5rem;
    --font-size: 20px;
    --title-font-size: 20px;

    color: var(--color);
    padding: var(--padding);
    font-size: var(--font-size);
    background-color: $light;

    &.message {
        --color: #{$nero-medio};
        --padding: 2rem;

        max-width: var(--max-width);
        margin: 0 auto;
        text-align: center;
    }

    .title {
        color: var(--color);
        font-size: var(--title-font-size);
        line-height: 1;
    }
}

.box-requirements {
    --font-size: 16px;
    --padV: 3px;

    .list-group-item {
        font-size: var(--font-size);
        padding-top: var(--padV);
        padding-bottom: var(--padV);
    }
}

.highlight-box {
    --size: 16px;
    --padding: 1rem;

    padding: var(--padding);
    font-size: var(--size);

    color: $text-reverse;
    background-color: $blue-sso;
    text-align: center;
}

@media (min-width: 992px) {
    .highlight-box {
        --size: 18px;
        --padding: 1rem 2rem;
    }
}

@media (min-width: 992px) {
    .pmi-card {
        --padding: 2rem 5rem;
        --max-width: 564px;
        --title-font-size: 30px;

        &.message {
            --padding: 2rem 3rem;
        }
    }
    .box-requirements {
        --font-size: 20px;
        --padV: 7px;
    }
}
