$main-font-family: 'Open Sans';
$alt-font-family: 'sans serif';

$giallo: rgb(240, 179, 50);
$light-blue: rgb(126, 226, 255);
$blue: #0d5274;
$blue-pmi: #0d5472;
$blue-sso: #005472; // 00
$blue-dark: #045681;
$blue-button: #07567d;
$light: #e6edf1;

$nero: #000;
$nero-medio: #212529;
$nero-scuro: #0d131a;
$nero-chiaro: #16212c;
$bianco: #fff;
$rosso: #fe0000;

$bianco-rosa: #ffd5ff;
$grigio: rgb(87, 87, 86);
$grigio-scuro: rgb(88, 88, 90);
$grigio-medio: rgb(136, 139, 179);
$grigio-chiaro: rgb(216, 216, 218);
$bianco-sporco: rgb(246, 246, 246);
$arancione: rgb(242, 99, 52);

$text-color: $blue;
$text-reverse: $bianco;
$text-highlight: $giallo;
$text-disabled: $grigio-chiaro;
$text-error: red;
$text-black: #000;
$text-dark: #3d3d3d;
$text-form-color: $bianco;

$main-header-bg: transparent;
$main-header-color: $bianco;

$link-color: $bianco;
$line-color: $bianco-rosa;

$background-color: $nero;
$background-highlight: $rosso;
$background-reverse: $bianco;

$selection-background: $blue-pmi;
$selection-color: $bianco;

$placeholder-color: $blue-pmi;
$placeholder-weight: 300;
$placeholder-font-size: var(--form-font-size);
$placeholder-font-style: normal;

$dark-background: rgba(0, 0, 0, 0.5);
$light-background: rgba(255, 255, 255, 0.7);
$blue-background: rgba(0, 56, 101, 0.95);
$white-background: rgb(255, 255, 255);
$black-background: rgb(0, 0, 0);

// AV LIVE BACKGROUNDS
$av-live-background: transparent;
$av-content-background: transparent;
$av-aside-background: transparent;
$room-box-background: $white-background;
$av-live-frame-border: $dark-background;

$checkbox-color: $text-color;
$checkbox-bg: $nero;

$msg-confirm-color: $text-highlight;
$msg-confirm-bg: $nero;

$button-color: $bianco;

$star-color: gold;

$survey-disabled-arrow: #5c5c5c;

$text-strong: bold;
$text-bold: 700;
$text-semibold: 600;
$text-regular: 400;
$text-light: 300;
$text-thin: 200;

:root {
    --color-yellow: #{$giallo};
    --color-light-blue: #{$light-blue};
    --color-blue: #{$blue};
    --color-blue-bis: #{$blue-pmi};
    --color-blue-button: #{$blue-button};

    --color-accent: var(--color-yellow);
    --color-primary: var(--color-blue);

    --layout-margin-left: 8vw;

    --logo-bg: transparent;
    --sidebar-bg: transparent;
    --font-size: 16px;
    --font-fair-size: 16px;

    --border-width: 7px;
    --bottom-border: 0 none; //7px solid var(--color-accent);

    --chat-color: #{$blue};
    --chat-bg: #{$blue-dark};
    --chat-send-bg: #fff;
    --chat-send-color: #{$blue};
    --chat-send-fw: 400;
    --chat-send-spacing: 3px;

    --btn-sso-bg: #{$blue-sso};
    --btn-form-bg: #{$blue-pmi};
    --btn-form-color: #{$bianco};
    --btn-form-size: 20px;
    --btn-form-spacing: 10px;
    --btn-form-padding: 10px 50px;

    --form-color: #{$blue-pmi};
    --form-padding: 30px 35px 20px 35px;
    --form-font-size: 18px;
    --form-max-width: none;
    --form-margin-bottom: 0;
    --form-margin-right: 0;
    --form-margin-left: 0;
}

.dummy {
    color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 992px) {
    :root {
        --font-fair-size: 20px;
    }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-1400px) translateY(100%);
        transform: translateZ(-1400px) translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-bottom {
    0% {
        -webkit-transform: translateZ(-1400px) translateY(100%);
        transform: translateZ(-1400px) translateY(100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0) translateY(0);
        transform: translateZ(0) translateY(0);
        opacity: 1;
    }
}
